.login {
  width: 100%;
  background-image: url("../../assets/login.jpg");
  position: absolute;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
@media (max-width: 767px) {
  .login {
    padding: 30px 0;
  }
}
.login h1 {
  color: white;
  text-align: center;
  margin-bottom: 100px;
}
.login form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px 25px;
  background-color: #1748C1;
  width: 30%;
  margin-left: 60px;
  height: 100vh;
}
@media (max-width: 767px) {
  .login form {
    width: 80%;
  }
}
.login form input {
  height: 35px;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding: 1%;
  border-radius: 1vh;
  outline: none;
}
.login form button {
  width: 40%;
  padding: 15px;
  border-radius: 10px;
  border-radius: 15px;
  border: 2px white;
  margin: auto;
  transition: 0.3s;
  cursor: pointer;
}
.login form button:hover {
  background-color: black;
  color: white;
}/*# sourceMappingURL=login.css.map */