.main-sec {
    background-color: #f9f9f9;
    border-radius: 8px;
    margin: 0 auto;
}

.table{
    border-radius: 8px;
    
    margin: 0 auto;
}

.main-sec h2 {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.members-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    border-collapse: collapse;
    width: 100%;
}

.members-table thead {
    background-color: #01004C;
    color: white;
}

.members-table th,
.members-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    width: 100%;
}

.members-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.members-table tbody tr:hover {
    background-color: #ddd;
}

.members-table th {
    background-color: #01004C;
    color: white;
}

.members-table td {
    color: #333;
}

@media (max-width: 767px) {
    .main-sec {
        padding: 10px;
    }

    .members-table th,
    .members-table td {
        padding: 8px;
    }
}