@import "../helpers/mixins";

.login {
    width: 100%;
    background-image: url('../../assets/login.jpg');
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    // padding: 30px;

    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;

    @include media(mopile) {

        padding: 30px 0;
    }

    h1 {
        color: rgb(7, 7, 7);
        text-align: center;
        margin-bottom: 100px;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 40px 25px;
        background: #1747c183;
        width: 30%;
        margin-left: 60px;
        height: 100vh;

        @include media(mopile) {
            width: 80%;

        }

        input {
            height: 35px;
            border: 1px solid white;
            text-align: center;
            width: 80%;
            margin: 0 auto;
            border-radius: 10px;
        }

        button {
            width: 40%;
            padding: 15px;
            border-radius: 10px;
            border-radius: 15px;
            border: 2px white;
            margin: auto;
            transition: 0.3s;
            cursor: pointer;

            &:hover {
                background-color: black;
                color: white;
            }
        }
    }
}