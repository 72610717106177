.side {
  background-color: #fcfcfc;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100%;
  gap: 20px;
  z-index: 50;
  padding: 20px;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .side {
    width: 90%;
    position: absolute;
    right: -120%;
    transition: 0.3s ease-in-out;
    background-color: white;
    max-height: 80%;
    padding: 20px;
    max-width: 70%;
    border-radius: 10px;
    bottom: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.side .logout {
  margin: 15px auto;
  padding: 10px;
  background-color: #2a77cf;
  color: white;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.side .logout:hover {
  background-color: #1748c1;
}

.side img {
  width: 150px;
  border-radius: 5px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .side img {
    display: none;
  }
}

.side .links {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  width: 90%;
  margin: 0 auto;
}

.side .links h2 {
  background-color: #01004c;
  margin: 0;
  text-align: center;
  border-radius: 10px;
  color: white;
  padding: 10px 0;

}

.side .links a {
  font-size: 18px;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  transition: all 0.2s;
  text-transform: capitalize;
  color: white;
  border-radius: 10px;
  text-decoration: none;
}

.side .links a.active {
  background-color: #1748c1;
  padding-left: 30px;
}

.side .links a:hover {
  background-color: #87ceea;
  padding-left: 30px;
}

.side .links .add {
  background-color: rgba(27, 231, 27, 0.6);
}

.side .links .add:hover {
  background-color: rgb(7, 238, 7);
}

@media (max-width: 767px) {
  .side-show {
    right: 12%;
  }
}
